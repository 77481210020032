<template>
	<section id="tw-view-single-department">
		<div class="bg-white">
			<div class="p-4">
				<div class="row position-relative">
					<div v-if="!requestResolved && !createNew" class="tw-table-loading-overlay pt-5 d-flex align-items-start justify-content-center position-absolute top-0 bottom-0 start-0 end-0 bg-white opacity-75">
						<i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
					</div>
					<div v-else class="col-12">
						<div class="tw-table-header row">
							<div class="col mb-3 d-flex align-items-center">
								<span v-if="!createNew">Published on: {{ moment(department.date).format('ll') }} at {{ moment(department.date).format('HH:mm') }}</span>
							</div>
							<div class="tw-single-department-flex-basis col-lg-6 col-xl-4 mb-3">
								<div class="row">
									<div class="col mb-3 mb-lg-0">
										<div class="form-group d-flex align-items-center">
											<label for="tw-department-status" class="me-2">Status:</label>
											<div class="tw-single-department-select-wrapper position-relative w-100">
												<span class="position-absolute top-0 bottom-0 start-0 end-0 tw-light-grey-bg-color"></span>
												<select class="form-select text-capitalize" v-model="status" id="tw-department-status" disabled>
													<option v-for="status in statuses" :value="status" v-html="status" :key="status"></option>
												</select>
											</div>
										</div>
									</div>
									<!-- <div class="col mb-3 mb-lg-0">
										<div class="form-group d-flex align-items-center">
											<label for="tw-department-status" class="me-2">Visibility:</label>
											<div class="tw-single-department-select-wrapper position-relative w-100">
												<span class="position-absolute top-0 bottom-0 start-0 end-0 tw-light-grey-bg-color"></span>
												<select class="form-select text-capitalize" v-model="department.visibility" id="tw-department-visibility:" :disabled="viewMode == 'show'">
													<option v-for="visibility in visibilities" :value="visibility" v-html="visibility" :key="visibility"></option>
												</select>
											</div>
										</div>
									</div> -->
								</div>
							</div>
							<div class="tw-single-department-flex-basis col-lg-6 col-xl-4 mb-3">
								<div class="row justify-content-end">
									<div class="col mb-3 mb-lg-0">
                                        <span v-if="!createNew" v-permission="{juniper: 'update_departments', model: 'departments', tooltip: true}">
                                            <button class="tw-default-bg-color text-white d-flex align-items-center ms-auto me-auto me-lg-0 w-100" :class="viewMode === 'edit' && 'active'" @click="changeViewMode">
                                                <span class="d-inline-flex justify-content-center align-items-center tw-grey-bg-color me-3">
                                                    <i class="fas fa-pencil-alt"></i>
                                                </span>
                                                <span class="ps-xl-2 d-inline-flex align-items-center flex-grow-1 pe-2">Edit Mode</span>
                                            </button>
                                        </span>
									</div>
									<div class="col mb-3 mb-lg-0">
										<a v-if="!createNew" class="tw-button tw-red-bg-color text-white d-flex align-items-center ms-auto me-auto me-lg-0 text-decoration-none px-2 w-100" target="_blank" :href="department.link">
											<span class="d-inline-flex justify-content-center align-items-center me-3">
												<i class="far fa-eye"></i>
											</span>
											<span class="ps-xl-2 d-inline-flex align-items-center flex-grow-1 pe-2">Preview</span>
										</a>
									</div>
								</div>
							</div>
							<div class="col-12 mt-4">
								<div v-if="viewMode === 'show'" class="w-100 pb-4 d-flex align-items-center">
									<img v-if="icon" :src="(icon.media_details && icon.media_details.sizes && icon.media_details.sizes.thumbnail) ? icon.media_details.sizes.thumbnail.source_url : icon" :alt="title" height="25">
									<span class="tw-single-department-default-icon d-flex align-items-center" v-else v-html="defaultIcon"></span>
									<span class="tw-single-department-title fw-bold ms-5" v-html="title"></span>
								</div>
								<div v-else class="w-100 pb-4 d-flex align-items-center">
									<!-- <input type="file" id="tw-single-department-icon-edit" class="form-control d-none" @change="handleFileUpload($event, 'department-icon')">
                                    <label class="tw-icon tw-light-grey-bg-color d-flex justify-content-center align-items-center cursor-pointer me-1" for="tw-single-department-icon-edit">
										<img v-if="icon" :src="(icon.media_details && icon.media_details.sizes && icon.media_details.sizes.thumbnail) ? icon.media_details.sizes.thumbnail.source_url : icon" :alt="department.title.rendered || department.title" height="25">
                                    </label> -->
									<!-- <input type="file" id="tw-single-department-icon-edit" class="form-control d-none" @change="handleFileUpload($event, 'department-icon')" required> -->
									<label for="tw-single-department-icon-edit" class="tw-single-department-icon-edit-active cursor-pointer d-flex flex-column border rounded py-2 px-3 position-relative" @click="showMediaLibraryModal('department-icon')">
										<small>Icon</small>
										<img v-if="icon" :src="(icon.media_details && icon.media_details.sizes && icon.media_details.sizes.thumbnail) ? icon.media_details.sizes.thumbnail.source_url : icon" :alt="title" height="25">
										<span v-if="icon" id="tw-department-icon-remove-icon" class="text-danger position-absolute" @click.stop.prevent="removeDepartmentIcon"><i class="fas fa-times-circle"></i></span>
										<span v-else class="tw-single-department-default-icon d-flex align-items-center" v-html="defaultIcon"></span>
										<span class="d-none">
											<i class="fa fa-spinner tw-fa-pulse border-0"></i>
										</span>
									</label>
									<div class="form-floating ms-5">
										<input type="email" class="form-control tw-form-floating-input" placeholder="Name" v-model="title" required>
										<label for="floatingInputGrid">Name</label>
									</div>
								</div>
							</div>
						</div>
						<div id="tw-single-department-content" class="tw-single-department-section mt-3 p-2" :class="viewMode === 'edit' && 'tw-light-grey-bg-color'">
							<div class="tw-single-department-section-header fw-bold p-2" :class="viewMode === 'show' ? 'tw-default-bg-color text-white' : 'tw-edit-header-bg-color'">
								<span>Content</span>
							</div>
							<editor
                            api-key=""
                            v-model="content"
                            theme="advanced"
                            theme_advanced_toolbar_location="bottom"
                            :disabled="viewMode === 'show'"
                            :init="tinymcInit"
                            />
						</div>


















						<div id="tw-featured-image-block" class="tw-single-department-section mt-3 p-2" :class="viewMode === 'edit' && 'tw-light-grey-bg-color'">
							<div class="tw-single-department-section-header fw-bold p-2 mb-3" :class="viewMode === 'show' ? 'tw-default-bg-color text-white' : 'tw-edit-header-bg-color'">
								<span>Featured Image</span>
							</div>
                            <div class="row flex-grow-1 align-items-center mb-sm-3">
                                <div class="col-md-6 mb-3 d-flex justify-content-center">
                                    <div class="position-relative">
                                        <label v-if="(viewMode == 'show' && featuredMedia) || viewMode == 'edit'" for="featured_media" class="d-flex tw-modal-featured-img cursor-pointer align-items-center justify-content-center">
                                            <img v-if="featuredMedia" :src="( featuredMedia && featuredMedia.media_details && featuredMedia.media_details.sizes && Object.keys(featuredMedia.media_details.sizes).length ) ? featuredMedia.media_details.sizes.thumbnail.source_url : featuredMedia.source_url || defaultImage" :alt="department.post_title || department.title" height="175" width="188">
                                            <span v-else class="d-flex align-items-center justify-content-center">
                                                <span v-html="defaultImage"></span>
                                            </span>
                                        </label>
                                        <span v-else class="d-flex my-4 justify-content-center"><i>There is no featured image for this deprtment.</i></span>

                                        <div v-if="imageUploads" id="tw-modal-featured-img-loading-overlay" class="position-absolute opacity-25 bg-dark top-0 start-0 end-0 bottom-0 opacity-75 tw-light-grey-bg-color d-flex align-items-center justify-content-center">
                                            <i class="fa fa-spinner fa-pulse fa-4x border-0"></i>
                                        </div>
                                        <div v-if="featuredMedia && viewMode === 'edit'" id="tw-modal-featured-img-overlay" class="position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center cursor-pointer" @mouseover="hoverOverlay = true" @mouseleave="hoverOverlay = false" :class="{'tw-modal-featured-img-overlay-hovered': hoverOverlay}">
                                            <div class="d-flex">
                                                <label for="featured_media" class="bg-dark rounded-circle d-flex justify-content-center align-items-center p-2 me-1 cursor-pointer">
                                                    <i class="fas fa-image fa-2x text-white"></i>
                                                </label>
                                                <span class="bg-dark rounded-circle d-flex justify-content-center align-items-center p-2 ms-1 cursor-pointer" @click="department.featured_media = null; featuredMedia = null">
                                                    <i class="fas fa-trash fa-2x text-white"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <input v-if="viewMode === 'edit'" ref="featured_media" type="file" class="d-none" id="featured_media" @change="handleFileUpload($event, 'featured-image')" @click="resetImageUploader" accept="image/png, image/jpg, image/jpeg">
                                </div>
                                <div v-if="viewMode === 'edit'" class="col-md-6 mb-3 d-none d-md-flex justify-content-center">
                                    <div class="tw-modal-media-lib d-flex align-items-center justify-content-center cursor-pointer" style="background: #eaeaea;" @click="showMediaLibraryModal('featured-image')">
                                        <div class="tw-modal-media-lib-btn tw-grey-bg-color d-flex align-items-center text-white">
                                            <i class="fas fa-plus"></i> <span class="ms-2">Media Library</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>





						<div id="tw-single-department-team" class="tw-single-department-section mt-3 p-2" :class="viewMode === 'edit' && 'tw-light-grey-bg-color'">
							<div class="tw-single-department-section-header fw-bold p-2" :class="viewMode === 'show' ? 'tw-default-bg-color text-white' : 'tw-edit-header-bg-color'">
								<span>Team</span><small class="ms-3">{{ (department.team && department.team.length) ? department.team.length : '0' }} member{{ ( department.team && (department.team.length > 1 || !department.team.length) ) ? 's' : '' }}</small>
							</div>
							<div class="table-responsive">
								<table class="table">
									<thead>
										<tr>
											<th>Photo</th>
											<th>Name</th>
											<th>Title</th>
											<th>Office days</th>
											<th>Office Hours</th>
											<th>Email</th>
											<th v-if="viewMode == 'edit'"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(teamMember, index) in team" :key="index">
											<td class="tw-single-department-team-member-img align-middle">
												<label class="position-relative" :class="viewMode === 'edit' && 'cursor-pointer'" :for="'imageInput'+index" v-on="(viewMode === 'edit') ? { click: () => showMediaLibraryModal('team', index)} : {}">
													<span v-if="teamMember.image && viewMode == 'edit'" class="text-danger position-absolute tw-department-icon-remove-team-member-img" @click.stop.prevent="removeDepartmentTeamMemberImg(index)"><i class="fas fa-times-circle"></i></span>
													<img v-if="teamMember.image" :src="teamMember.image.source_url ||teamMember.image" :alt="teamMember.name" class="tw-default-bg-color" width="39" height="39">
													<span v-else v-html="defaultTeamMemberImg" class="tw-default-text-color"></span>
												</label>
												<span class="d-none">
													<i class="fa fa-spinner tw-fa-pulse border-0"></i>
												</span>
												<!-- <span v-if="viewMode === 'edit'">
													<input :id="'imageInput'+index" type="file" class="d-none" @change="handleFileUpload($event, 'team', index)" accept="image/png, image/jpg, image/jpeg">
												</span> -->
											</td>
											<td class="align-middle">
												<span v-if="viewMode === 'show'">{{ teamMember.name }}</span>
												<span v-else><input class="form-control" :class="(!team[index].name && memberErrors.includes(index)) && 'is-invalid'" type="text" v-model="team[index].name"></span>
											</td>
											<td class="align-middle">
												<span v-if="viewMode === 'show'">{{ teamMember.title }}</span>
												<span v-else><input class="form-control" :class="(!team[index].title && memberErrors.includes(index)) && 'is-invalid'" type="text" v-model="team[index].title"></span>
											</td>
											<td class="align-middle">
												<span v-if="viewMode === 'show'">{{ teamMember.office_days }}</span>
												<span v-else><input class="form-control" :class="(!team[index].office_days && memberErrors.includes(index)) && 'is-invalid'" type="text" v-model="team[index].office_days"></span>
											</td>
											<td class="align-middle">
												<span v-if="viewMode === 'show'">{{ teamMember.office_hours }}</span>
												<span v-else><input class="form-control" :class="(!team[index].office_hours && memberErrors.includes(index)) && 'is-invalid'" type="text" v-model="team[index].office_hours"></span>
											</td>
											<td class="align-middle">
												<span v-if="viewMode === 'show'">{{ teamMember.email }}</span>
												<span v-else><input class="form-control" type="text" v-model="team[index].email"></span>
											</td>
											<td class="align-middle" v-if="viewMode == 'edit'">
												<span @click="removeTeamMember( index, $event )">
													<i class="fas fa-minus-circle tw-red-color cursor-pointer"></i>
												</span>
											</td>
										</tr>
										<tr v-if="viewMode === 'edit'">
											<td colspan="7" class="text-end">
												<span class="cursor-pointer" @click="addTeamMember"><i class="fas fa-plus-circle"></i> Add Row</span>
											</td>
										</tr>
									</tbody>
									<tfoot class="py-1">
										<tr>
											<td class="p-0 pt-2"></td>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
						<div id="tw-single-department-contact-us" class="tw-single-department-section mt-3 p-2" :class="viewMode === 'edit' && 'tw-light-grey-bg-color'">
							<div class="tw-single-department-section-header fw-bold p-2" :class="viewMode === 'show' ? 'tw-default-bg-color text-white' : 'tw-edit-header-bg-color'">
								<span>Contact Us</span>
							</div>
							<div class="table-responsive">
								<table class="table">
									<thead>
										<tr>
											<th>Title</th>
											<th>Text</th>
											<th v-if="viewMode == 'edit'"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(row, index) in contactUs" :key="index">
											<td class="align-middle">
												<span v-if="viewMode === 'show'">{{ row.title }}</span>
												<span v-else><input class="form-control" :class="(!contactUs[index].title && contactErrors.includes(index)) && 'is-invalid'" type="text" v-model="contactUs[index].title"></span>
											</td>
											<td class="align-middle">
												<span v-if="viewMode === 'show'">{{ row.text }}</span>
												<span v-else><input class="form-control" :class="(!contactUs[index].text && contactErrors.includes(index)) && 'is-invalid'" type="text" v-model="contactUs[index].text"></span>
											</td>
											<td class="align-middle" v-if="viewMode == 'edit'">
												<span @click="removeContactUsRow( index, $event )">
													<i class="fas fa-minus-circle tw-red-color cursor-pointer"></i>
												</span>
											</td>
										</tr>
										<tr v-if="viewMode === 'edit'">
											<td colspan="3" class="text-end">
												<span class="cursor-pointer" @click="addContactUsRow"><i class="fas fa-plus-circle"></i> Add Row</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div v-if="siteType == 'cms6'" id="tw-single-department-contact-us" class="tw-single-department-section mt-3 p-2" :class="viewMode === 'edit' && 'tw-light-grey-bg-color'">
							<div class="tw-single-department-section-header fw-bold p-2" :class="viewMode === 'show' ? 'tw-default-bg-color text-white' : 'tw-edit-header-bg-color'">
								<span>Department menu</span>
							</div>
                            <select v-if="menuId || viewMode === 'edit'" class="form-control my-4 text-capitalize" required v-model="menuId" id="post-status" :disabled="viewMode == 'show'">
								<option :value="null">-- Choose menu --</option>
                                <option v-for="menu in menus" :value="menu.id" v-html="menu.name" :key="menu.id"></option>
                            </select>
                            <span v-else class="d-flex my-4 justify-content-center"><i>No menu connected to the deprtment.</i></span>
						</div>
						<div v-if="siteType == 'cms6' && userHasCms6Permission('add_user_to_post')" id="tw-single-department-editors" class="tw-single-department-section mt-3 p-2" :class="viewMode === 'edit' && 'tw-light-grey-bg-color'">
							<div class="tw-single-department-section-header fw-bold p-2" :class="viewMode === 'show' ? 'tw-default-bg-color text-white' : 'tw-edit-header-bg-color'">
								<span>Editors</span>
							</div>
							<Multiselect
								v-model="editors"
								mode="tags"
								class="tw-editor-select mt-3"
								:close-on-select="false"
								:searchable="false"
								:create-option="false"
								:hideSelected="false"
								:options="clientUsers"
								placeholder="-- Choose Editors --"
								:disabled="viewMode == 'show'"
							/>
						</div>
						<div id="tw-single-department-edit-footer" class="mt-3" v-if="viewMode === 'edit'">
							<button class="tw-default-bg-color text-white d-block d-lg-flex align-items-center ms-auto justify-content-center px-3 me-auto me-lg-0" @click="storeNewDepartment">
								<span class="ps-xl-3 d-inline-flex align-items-center flex-grow-1">Save Changes</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<mediaLibraryModal @mediaInsert="insertImage($event)" ref="mediaLibraryModal" />
	<!-- <mediaLibraryModal @mediaInsert="insertPhoto($event)" :showMediaLibrary="showMediaLibrary" ref="mediaLibraryModal" /> -->
</template>

<script>
	import wordpressMixin from '@/mixins/wordpressMixin';
	import cms6Mixin from '@/mixins/cms6Mixin';
	import siteMixin from '@/mixins/siteMixin';
	import editor from '@tinymce/tinymce-vue';
	import tinymceConfig from '@/tinymce/departmentConfig';
	import mediaLibraryModal from "@/components/modals/mediaLibraryModal";
	import Multiselect from "@vueform/multiselect";

	let site = localStorage.getObject('site');


	let mixins;

	if (site.type == 'wordpress') {
		mixins = [wordpressMixin, siteMixin];
	} else if(site.type == 'cms6') {
		mixins = [cms6Mixin, siteMixin];
	}

	export default {
		name: 'Department',
		components: {
			editor,
			mediaLibraryModal,
			Multiselect,
		},
		data: () => ({
			department: {},
			title: '',
			content: '',
			status: 'draft',
			icon: false,
			team: [],
			contactUs: [],
			requestResolved: false,
			viewMode: 'show', // edit
			statuses: ['publish', 'draft'],
			visibilities: ['public', 'private', 'password_protected'],
			defaultIcon: '<div class="tw-default-icon"><i class="fas fa-image"></i></div>',
			defaultTeamMemberImg: '<div class="tw-default-image"><i class="fas fa-user-circle"></i></div>',
			createNew: false,
			memberErrors: [],
			contactErrors: [],
			mediaInsertType: null,
			mediaInsertIndex: null,
			defaultImage: '<div class="tw-defaul-featured-img tw-light-grey-bg-color d-flex align-items-center"><i class="fas fa-image me-2"></i> <span>Upload Image</span></div>',
			featuredMedia: null,
			hoverOverlay: false,
			imageUploads: false,
			menu: {},
			menuId: null,
			menus: [],
			editors: []
		}),
		mounted() {
			if(this.siteType == 'cms6') {
				this.setMenus();
			}
			if (this.slug) {
				this.setDepartment();
			} else {
				this.viewMode = 'edit';
				this.createNew = true;
			}
		},
		watch: {
			'$route'() {
				this.createNew = false;
				this.setDepartment();
			}
		},
		methods: {
			async setDepartment() {

				let res = await this.getDepartmentBySlug(this.slug);

				if (res && res.status === 200) {
					this.requestResolved = true;
					this.department = res.data[0];

					this.title = this.department.title.rendered || this.department.title;
					this.content = this.department.content && this.department.content.rendered || this.department.content;
					this.menuId = this.department.menu_id;
					this.icon = this.department.icon;
					this.status = this.department.status;

					let self = this;

					if (this.department._embedded && this.department._embedded['wp:featuredmedia']) {
						this.featuredMedia = this.department._embedded['wp:featuredmedia'].find(function(media) {
												return media.id == self.department.featured_media;
											});
					} else if(this.department.featured_media && this.department.featured_media.media_details && this.department.featured_media.media_details.sizes && Object.keys(this.department.featured_media.media_details.sizes).length) {
						this.featuredMedia = this.department.featured_media;
					}

					// this.featuredMedia = this.department.featured_media;

					if (this.department.team) {
						this.team = await this.department.team;
					}
					if (this.department.contact_us) {
						this.contactUs = this.department.contact_us;
					}

					this.editors = this.department.editors;
				}
			},
			changeViewMode() {
					// console.log(this.$el.querySelector(`label[for="tw-single-department-icon-edit"]`))
				if (this.viewMode === 'show') {
					this.viewMode = 'edit';
				} else {
					this.viewMode = 'show';
				}
			},
			removeTeamMember(index) {
				this.team.splice(index, 1);
			},
			removeContactUsRow(index) {
				this.contactUs.splice(index, 1);
			},
			async storeNewDepartment() {
				this.department.team = this.team;
				this.department.contact_us = this.contactUs;
				// if (this.createNew) {
				// 	this.department.icon = this.icon;
				// }

				this.department.featured_media = this.featuredMedia ? this.featuredMedia.id : null;

				if (this.siteType == 'cms6') {
					this.department.icon = this.icon ? this.icon.id : null;
					this.department.menu_id = this.menuId;
					if (this.editors) {
						this.department.editors = this.editors;
					}
				} else {
					this.department.icon = (this.icon && this.icon.source_url) ? this.icon.source_url : this.icon;
				}

				this.department.title = this.title;
				this.department.content = this.content;
				this.department.status = "publish";

				this.department.comment_status = 'closed';
                this.department.ping_status = 'closed';

				// delete this.department.featured_media;
				// this.updateDepartment(this.department);
				this.$swal({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    icon: 'warning',
                    text: "Are you sure you want to save department?",
                    confirmButtonText: 'Yes, save it!',
                    showLoaderOnConfirm: true,
					preConfirm: async () => {
						if (this.department.title === '') {
							this.$swal.showValidationMessage(
								`Request failed: Department name is required.`
							)
							return;
						}

						let error = this.validateDepartmentData();

						if (error) {
							if (error == 'member') {
								this.$swal.showValidationMessage(
									`Request failed: Some team member fields are required.`
								)
								return;
							}

							if (error == 'contact') {
								this.$swal.showValidationMessage(
									`Request failed: Some contact us fields are required.`
								)
								return;
							}
						}

						try {
							if (this.createNew) {
								return await this.saveDepartment(this.department);
							} else {
								return await this.updateDepartment(this.department);
							}

						} catch(error) {
							this.$swal.showValidationMessage(
								`Request failed: ${error}`
							)
						}
					},
					allowOutsideClick: () => !this.$swal.isLoading()
                }).then(async (result) => {
					if (result.isConfirmed) {

						this.$swal(
							'Department saved!',
							"Department has been saved!",
							'success',
						)
						.then(() => {

							this.$router.push(`/departments/${result.value.data.slug}`);
							// this.setDepartment();

						});

					}
                });
			},
			async handleFileUpload (event, type) {


				// if (type == 'team') {
				// 	this.$el.querySelector(`label[for="${event.target.id}"]`).classList.add('d-none');
				// 	this.$el.querySelector(`label[for="${event.target.id}"] + span`).classList.remove('d-none');
				// } else {
				// 	this.$el.querySelector(`label.tw-single-department-icon-edit-active span:not(:last-child)`).classList.add('d-none');
				// 	this.$el.querySelector(`label.tw-single-department-icon-edit-active span:last-child`).classList.remove('d-none');
				// }
				this.imageUploads = true;
				let res;

				if (event.target.files[0]) {
					res = await this.uploadFile(event.target.files[0], true);
				}

				if (type === 'featured-image') {
					this.featuredMedia = res ? await res.data : false;
				}

				this.imageUploads = false;


                // if (type === 'team') {

				// 	this.team[index].image = res ? await res.data.source_url : false;

				// 	if (this.siteType == 'cms6') {
				// 		this.team[index].image_id = res ? await res.data.id : false;
				// 	}

                // } else if ( type === 'department-icon' ) {
				// 	this.icon = res ? await res.data : false;
                // }

                // if (type == 'team') {
				// 	this.$el.querySelector(`label[for="${event.target.id}"]`).classList.remove('d-none');
				// 	this.$el.querySelector(`label[for="${event.target.id}"] + span`).classList.add('d-none');
				// } else {
				// 	this.$el.querySelector(`label.tw-single-department-icon-edit-active span:not(:last-child)`).classList.remove('d-none');
				// 	this.$el.querySelector(`label.tw-single-department-icon-edit-active span:last-child`).classList.add('d-none');
				// }
            },
            addTeamMember() {
				let teamMemberShema = {
					name: "",
					title: "",
					image: false,
					email: "",
					office_days: "",
					office_hours: "",
				};
				this.team.push(teamMemberShema);
            },
            addContactUsRow() {
				let contactUsRowShema = {
					title: "",
					text: "",
				}
				this.contactUs.push(contactUsRowShema);
            },
            validateDepartmentData() {


				let teamRequired = ['name', 'title', 'office_days', 'office_hours'];
				let contactRequired = ['title', 'text'];

				let errors = [];

				let self = this;

				this.department.team.forEach(function(member, index){
					teamRequired.forEach(function(req){
						if (!member[req]) {
							!self.memberErrors.includes(index) && self.memberErrors.push(index)
							errors.push(req);
						}
					})
				})

				if (errors.length) {
					return 'member';
				}


				this.department.contact_us.forEach(function(contact, index){
					contactRequired.forEach(function(req){
						if (!contact[req]) {
							!self.contactErrors.includes(index) && self.contactErrors.push(index)
							errors.push(req);
						}
					})
				})

				if (errors.length) {
					return 'contact';
				}

				return null;
            },
			showMediaLibraryModal(type, index = null) {
				this.mediaInsertType = type;
				this.mediaInsertIndex = index;
				this.$refs.mediaLibraryModal.showModal(true);
			},
			insertImage(image) {

				if (this.mediaInsertType === 'team') {


					this.team[this.mediaInsertIndex].image = ( image.media_details && image.media_details.sizes && image.media_details.sizes.thumbnail && image.media_details.sizes.thumbnail.source_url ) ? image.media_details.sizes.thumbnail.source_url : image.source_url;

					if (this.siteType == 'cms6') {
						this.team[this.mediaInsertIndex].image_id = image.id;
					}

                } else if ( this.mediaInsertType === 'department-icon' ) {
					this.icon = image;
                } else if ( this.mediaInsertType === 'featured-image' ) {
					this.featuredMedia = image;
                }

			},
			removeDepartmentIcon() {
				this.icon = null;
			},
			removeDepartmentTeamMemberImg(index) {

				if (this.siteType == 'cms6') {
					this.team[index].image = null;
					this.team[index].image_id = null;
				} else {
					this.team[index].image = '';
				}
			},
			async setMenus() {
				let res = await this.getMenus();
                this.menus = await res.data;
			}
		},
		computed: {
			slug() {
				return this.$route.params.slug;
			},
			tinymcInit() {
                return tinymceConfig.config;
            },
            siteType() {
				return this.getSiteType();
            },
			clientUsers() {
				let users = this.getClientUsers().map(user => {
					return { value: user.id, label: user.name };
				});
				console.log(users);
				return this.getClientUsers().map(user => {
					return { value: user.id, label: user.name };
				});
			}
		},
		mixins: mixins
    }
</script>
<style scoped>
	.tw-table-header button.active {
		-webkit-box-shadow: 0px 0px 10px 5px var(--tw-light-red);
		box-shadow: 0px 0px 10px 5px var(--tw-light-red);
	}
	.tw-edit-header-bg-color {
		background: #eaeaea;
	}
	.tw-table-header .form-floating label {
		font-size: 14px;
	}
	.tw-table-header .form-floating input {
		font-size: 24px;
		color: var(--tw-default-font-color);
		font-weight: 700;
	}
	label[for='tw-single-department-icon-edit'] small {
		font-size: 12px;
	}
	.tw-table-header > div:last-child > div {
		border-bottom: 1px solid #E0E0E0;
	}
	.tw-single-department-select-wrapper span {
		z-index: -1;
	}
	.tw-single-department-select-wrapper select {
		border-radius: 10px !important;
	}
	.tw-single-department-title {
		font-size: 24px;
	}
	.tw-single-department-default-icon {
		font-size: 25px;
	}
	.tw-single-department-section {
		border: 1px solid #E0E0E0;
		border-radius: 4px;
	}
	.tw-single-department-section .tw-single-department-section-header {
		border-radius: 5px;
		font-size: 24px;
	}
	.tw-single-department-section .tw-single-department-section-header small {
		font-size: 18px;
		font-weight: 300;
	}
	table {
		margin-bottom: 0;
		color: var(--tw-default-font-color);
	}
	table thead tr th {
		line-height: 35px;
		color: var(--tw-default-font-color);
		font-size: 18px;
		white-space: nowrap;
	}
	#tw-single-department-team td {
		border: none;
	}
	#tw-single-department-team tbody,
	#tw-single-department-team tfoot,
	#tw-single-department-team thead tr {
		border-bottom: 1px solid #E0E0E0;
	}
	table tbody tr:last-child td {
		border: 0;
	}
	table tbody,
	table tfoot {
		border-top: none !important;
	}
	.tw-single-department-team-member-img img {
		border-radius: 50%;
	}
	.tw-single-department-team-member-img {
		font-size: 39px;
	}
	#tw-single-department-contact-us tbody td {
		padding-top: 35px;
		padding-bottom: 35px;
	}
	#tw-single-department-edit-footer button {
		height: 45px;
		font-size: 18px;
		border-radius: 10px;
		border: none;
	}
	@media only screen and (min-width: 998px) {
		.tw-table-header button,
		a.tw-button {
			max-width: 165px;
			white-space: nowrap;
		}
		.tw-single-department-flex-basis {
			flex-basis: content;
		}
	}
	#tw-modal-featured-img-loading-overlay {
        border-radius: 10px;

    }
    #tw-modal-featured-img-overlay {
        transition: .3s;
        background: rgba(255, 255, 255, 0);
    }
    #tw-modal-featured-img-overlay * {
        opacity: 0;
        transition: .3s;
    }
    #tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered {
        background: rgba(255, 255, 255, .25);
    }
    #tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered * {
        opacity: 1;
    }
    #tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered > div > span:hover,
    #tw-modal-featured-img-overlay.tw-modal-featured-img-overlay-hovered > div > label:hover {
        transform: scale(1.1);
    }
    #tw-modal-featured-img-overlay > div > span {
        width: 50px;
        height: 50px;
    }
    #tw-department-icon-remove-icon {
		right: -10px;
		top: -10px;
    }
    .tw-department-icon-remove-team-member-img {
		font-size: 16px;
		right: -10px;

    }
</style>
